.Accordion {

    >.center {
        left: var(--x);
        top: var(--y);

        >.bounds {
            position: absolute;
            left: 0;
            top: 0;
            width: var(--here-width);
            height: var(--here-height);
            outline-offset: -0.5px;
            pointer-events: none;
            // outline: 0.5px dashed greenyellow;
        }

        &.🁣>.bounds {
            width: 200vw;
            left: -50vw;
        }

        &.🀱>.bounds {
            height: 200vh;
            top: -50vh;
        }

    }

    &.focusHasBody>.center {
        height: var(--here-height);
        width: var(--here-width);
    }

    &.focusHasBody.🪗🀱>.center {
        left: var(--x);
    }

    &.focusIsBackground>.🀱:not(.center),
    &.focusIsBackground>.🁣:not(.center) {
        z-index: 2;
    }

    &.focusIsBackground>.🀱:not(.center):hover,
    &.focusIsBackground>.🁣:not(.center):hover {
        background: black;
    }


    &.focusIsBackground>.center {
        width: 100vw;
        height: 100vh;
        // left: 0px;
        // top: calc(0px - var(--padding));
        z-index: 1;
        top: calc(var(--y) - 50vh + 12rem);

        >.body {
            /* fullscreen background:
            - behind content
            - snaps to screen bottom left (so that play button is visible)
            - height is 100vh
            - width is either 16:9 portrait or 16:9 landscape, depending on device orientation
            */
            z-index: 0;
            overflow: visible;
            bottom: 0px;
            left: 0;
            min-width: 100vw;
            min-height: 100vh;
            position: absolute;

            @media (orientation:portrait) {
                width: calc(100vh / 16 * 9);
                max-height: 100vh;
            }

            @media (orientation:landscape) {
                width: calc(100vh / 9 * 16);
                max-height: 100vh;
            }

            opacity: 1
        }
    }

    &:not(.focusIsBackground) {
        >.center {
            z-index: 8;

            transition: all .7s;
        }

        >.center::before,
        >.A::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            mix-blend-mode: multiply;
            background: transparent;
            z-index: -1;
            pointer-events: none;
            transition: all .7s;
            width: 100%;
            height: var(--here-height);
        }

        >.center::before {
            width: var(--here-width);
        }
    }





    &:not(.focusIsBackground)>.center:not(.hasBody) {


        a {
            color: white;
        }

        mix-blend-mode:hard-light;

        &::before {
            background: #3322Fe;
        }

        &:hover::before {
            background: #0000ff;
        }

    }



    &:not(.focusHasBody)>.center {
        top: var(--y);


        &.🁣 {
            height: var(--center-height);

            >*:not(.body):not(.bounds) {
                height: var(--center-height);
            }

            width: var(--column);
        }

        &.🀱 {

            >.segmentLabel {
                writing-mode: vertical-rl;
                padding: 1.125rem 1rem;
            }

            width: var(--center-width);
        }


    }

}