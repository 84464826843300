@use 'Accordion/Region';

* {
    // scroll-behavior:auto;
}

:root {
    --x-offset: 0px;
    --y-offset: 0px;
}

/* The pivot circle that appears when scrolling */

.model::after {
    --radius: 5rem;
    position: fixed;
    left: calc(50vw - var(--radius));
    top: calc(40vh - var(--radius));
    border: var(--radius) solid white;
    mix-blend-mode: difference;
    content: ".";
    overflow: hidden;
    height: 0;
    width: 0;
    transition: all 0.2s;
    pointer-events: none;
    opacity: 0;
    border-radius: 50%;
}

.is-scrolling .model::after {
    --radius: 3rem;
    opacity: 1;
}

.Accordion {
    /* Cumulative measures */
    --west-width: calc(var(--west-units) * var(--unit));
    --east-width: calc(var(--east-units) * var(--unit));
    --north-height: calc(var(--north-units) * var(--unit));
    --south-height: calc(var(--south-units) * var(--unit));
    --info-height: calc(var(--north-infoLines) * var(--unit));

    --nearNorth-height: calc(var(--nearNorth-units) * var(--unit));
    --nearSouth-height: calc(var(--nearSouth-units) * var(--unit));



    /* What is the difference between 'center' and 'here'?
    'here' includes the 'peek' region, which is "behind" the 
    'center' region but has fixed, preset dimensions (see Layout.scss)
    */


    // `here` and `center` demensions depend on whether focus has body
    &.focusHasBody {
        --here-width: var(--center-width);
        --here-height: var(--center-height);
        --center-width: calc(var(--center-columns) * var(--column) + var(--center-screens) * var(--screen));
        --center-height: var(--fit-height);
    }

    &:not(.focusHasBody) {
        --here-width: max(var(--peek-width), var(--center-width));
        --here-height: max(var(--peek-height), var(--center-height));

        &.🪗🁣 {
            --center-height: calc(var(--unit) * var(--center-infoLines) + var(--unit));
            --center-width: var(--column);
        }

        &.🪗🀱 {
            --center-height: var(--column);
            --center-width: var(--unit);
        }
    }

    position:relative;
    height: var(--height);
    width: var(--width);
    overflow:hidden;
    contain:strict;

    /*---- focusIsBackground and not ----*/


    &.focusIsBackground {
        --here-height: var(--column);
        // --x: 0px;
        // --y: calc(50vh - var(--column) / 2);
        // --width: 100vw;
        // --height: calc(200vh - var(--padding) * 2);

        /* Aisles are collapsed to their headers */
        --nearWest-width: calc(var(--nearWest-units) * var(--unit));
        --nearEast-width: calc(var(--nearEast-units) * var(--unit));


        // left: 0;
    }

    --x: calc(var(--width) / 2 - 50vw + var(--x-offset));
    --y: calc(var(--height) / 2 - 50vh + var(--y-offset));
    --width: calc(var(--column) * 97);
    --height: 1100vh;

    &:not(.focusIsBackground) {



        /* Aisles expand around horizontal focus */
        --nearWest-width: calc(var(--nearWest-headers) * var(--unit) + var(--nearWest-columns) * var(--column) + var(--nearWest-screens) * var(--screen));
        --nearEast-width: calc(var(--nearEast-headers) * var(--unit) + var(--nearEast-columns) * var(--column) + var(--nearEast-screens) * var(--screen));

    }

}




/* ================================================================================
*                                  Rectangles 
*/



.Accordion {
    /* closest-aisle.js makes sure margins correspond to the vector 
from the focus-pivot to the top left edge of the screen.
This means that #virtualScreen, if given a position that corresponds to the location of the .F-pivot relative to the Accordion,
accurately fills the viewport at the moment the focus has changed, and can consequently be used to hard-scroll onto the new .F */


    /* PiVOT */
    --px: calc(50vw - var(--column) / 2 + 1.5rem);
    --py: 40vh;


    #virtualScreen {
        height: 100vh;
        width: 100vw;
        position: absolute;

        // left and top correspond to .F-pivot, on top of which the margins emulate scroll-offset
        left: calc(var(--x) - var(--px));
        top: calc(var(--y) - var(--py));
        transition: none;
        z-index: 30;

        outline-offset: -3px;
        pointer-events: none;
    }

    #virtualScreen.navigatingByScroll~li {
        transition: none !important;
    }


    #xy {
        display: none;
        background: #ad3;
        width: .4rem;
        height: .4rem;
        transform: rotate(45deg);
        left: calc(var(--x) - .2rem);
        top: calc(var(--y) - .2rem);
        z-index: 40;
        position: absolute;

    }

    /* The pivot is the point that selects the focus through scrolling. It's glued to the viewport. */
    #pivot {
        position: fixed;
        left: var(--px);
        top: var(--py);

        z-index: 40;
        mix-blend-mode: difference;

        &::after {
            position: absolute;
            left: -1rem;
            top: -1rem;
            content: ".";
            height: 0;
            overflow: hidden;
            display: block;
            border: 1rem solid transparent;
            border-left: 1rem solid white;
        }

    }


    .screenBackground {
        position: absolute;
        width: 100vw;
        height: 80vh;
        // outline: 2px dashed violet;
        outline-offset: -0.5rem;
        pointer-events: none;
        left: calc(var(--x) + var(--here-width) / 2 - 50vw);
        top: calc(var(--y) + var(--unit) / 2 - var(--info-height) / 2 + var(--here-height) / 2 - 40vh);
        pointer-events: none;
        background: transparent;
    }

    .aisleBackground {
        width: calc(var(--width) - var(--west-width) - var(--east-width));
        height: calc(var(--height) - var(--north-height) - var(--south-height) + var(--info-height));
        border: none;
        box-sizing: border-box;
        pointer-events: none;
        position: absolute;
        left: var(--west-width);
        top: calc(var(--north-height) - var(--info-height));
        background: transparent;
        z-index: 5;
    }


}


.Accordion {

    /* Mitigate list style */
    list-style: none;
    padding: 0;

    /* Allow absolute positioning */
    position: relative;

    /* Centering */
    margin: auto;
    top: var(--padding);
}

/* Article Layout */
.body {
    /* crop and provide scrollbars */
    max-height: var(--here-height);

    &:not(.illustrative) {
        overflow: auto;
    }

    &.waiting {
        background: grey;
    }
}



/*
===========================================================
*/
.hamburgerMenu,
.hamburgerMenu:link,
.hamburgerMenu:visited {
    position: fixed;
    top: 0;
    right: 0;
    color: white;
    z-index: 4;
    border-radius: 100%;
    padding: .75rem;
    width: 3rem;
    height: 3rem;
    background: rgba(0, 0, 0, .5);
    transition: all 1.4s;
}

.hamburgerMenu:hover {

    background: white;
    color: black;
    border-top-right-radius: 0;
}

.hamburgerMenu.hidden {
    opacity: 0;
    transform: scale(5);
    pointer-events: none;
}

.focusIsRoot {
    >.aisleBackground {
        opacity: 0;
    }
}


svg {
    color: inherit;
    stroke: rgba(0, 0, 0, .5);
    fill: currentColor;
}






//--------


ul.overflow,
ul.Accordion,
ul:not(.body ul) {
    padding-inline-start: 0;
    padding-left: 0;
    list-style-type: none;
}




//--------

.Accordion.focusIsBackground.focusIsRoot>.A {
    animation: scrollIn 2s ease-out 2s;
    animation-delay: calc(5s - 0.2s * var(--units));
    top: calc(var(--y) + 70vh + var(--units) * var(--unit))
}


@keyframes scrollIn {
    0% {
        margin-top: calc(0px + var(--units) * var(--unit) - 100vh);

    }


    100% {
        margin-top: 0px;
    }
}

@keyframes fadeIn {
    0% {
        opacity:0;

    }


    100% {
        opacity:1;
    }
}