.Accordion > .cache {
  visibility: hidden;
  opacity: 0;
  left: var(--x);
  top: var(--y);
  transition: height .3s, width .3s, left .2s, top .5s, opacity .3s linear .2s, visibility 0s linear .5s;
  position: absolute;
}

.Accordion > .cache.🀱 {
  width: var(--unit);
}

.Accordion > .cache.🁣 {
  height: var(--unit);
}

.Accordion > :not(.cache) {
  transition: opacity, height .5s, width .5s, left .2s, top .7s;
  position: absolute;
}

.Accordion > .🀱:not(.cache):not(.hasBody) {
  width: var(--unit);
}

.Accordion > .🁣:not(.cache), .Accordion > .🁣:not(.cache):not(.hasBody) {
  height: var(--unit);
}

.Accordion > .peek {
  overflow: hidden;
}

.Accordion.focusHasBody > .peek {
  left: calc(var(--x)  + var(--column) / 2);
  top: calc(var(--y)  + var(--here-height) / 2);
  width: 0;
  height: 0;
}

.Accordion.🪗🁣:not(.focusHasBody) > .peek {
  left: var(--x);
  top: var(--y);
  width: var(--here-width);
  height: var(--peek-height);
  box-sizing: border-box;
  z-index: 1;
  background: #44f;
}

.Accordion.🪗🁣:not(.focusHasBody) > .peek img {
  object-fit: cover;
  max-width: max(100vw, 100vh);
  min-width: 100%;
  height: 100%;
}

.Accordion.🪗🀱:not(.focusHasBody) > .peek {
  left: calc(var(--x) );
  top: var(--y);
  width: var(--peek-width);
  height: var(--column);
  box-sizing: border-box;
}

.Accordion.🪗🀱:not(.focusHasBody) > .peek img {
  max-width: 100vw;
  width: auto;
  height: 100%;
}

.Accordion > .center {
  left: var(--x);
  top: var(--y);
}

.Accordion > .center > .bounds {
  width: var(--here-width);
  height: var(--here-height);
  outline-offset: -.5px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.Accordion > .center.🁣 > .bounds {
  width: 200vw;
  left: -50vw;
}

.Accordion > .center.🀱 > .bounds {
  height: 200vh;
  top: -50vh;
}

.Accordion.focusHasBody > .center {
  height: var(--here-height);
  width: var(--here-width);
}

.Accordion.focusHasBody.🪗🀱 > .center {
  left: var(--x);
}

.Accordion.focusIsBackground > .🀱:not(.center), .Accordion.focusIsBackground > .🁣:not(.center) {
  z-index: 2;
}

.Accordion.focusIsBackground > .🀱:not(.center):hover, .Accordion.focusIsBackground > .🁣:not(.center):hover {
  background: #000;
}

.Accordion.focusIsBackground > .center {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: calc(var(--y)  - 50vh + 12rem);
}

.Accordion.focusIsBackground > .center > .body {
  z-index: 0;
  min-width: 100vw;
  min-height: 100vh;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: visible;
}

@media (orientation: portrait) {
  .Accordion.focusIsBackground > .center > .body {
    width: 56.25vh;
    max-height: 100vh;
  }
}

@media (orientation: landscape) {
  .Accordion.focusIsBackground > .center > .body {
    width: 177.778vh;
    max-height: 100vh;
  }
}

.Accordion:not(.focusIsBackground) > .center {
  z-index: 8;
  transition: all .7s;
}

.Accordion:not(.focusIsBackground) > .center:before, .Accordion:not(.focusIsBackground) > .A:before {
  content: " ";
  mix-blend-mode: multiply;
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: var(--here-height);
  background: none;
  transition: all .7s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.Accordion:not(.focusIsBackground) > .center:before {
  width: var(--here-width);
}

.Accordion:not(.focusIsBackground) > .center:not(.hasBody) {
  mix-blend-mode: hard-light;
}

.Accordion:not(.focusIsBackground) > .center:not(.hasBody) a {
  color: #fff;
}

.Accordion:not(.focusIsBackground) > .center:not(.hasBody):before {
  background: #3322fe;
}

.Accordion:not(.focusIsBackground) > .center:not(.hasBody):hover:before {
  background: #00f;
}

.Accordion:not(.focusHasBody) > .center {
  top: var(--y);
}

.Accordion:not(.focusHasBody) > .center.🁣 {
  height: var(--center-height);
  width: var(--column);
}

.Accordion:not(.focusHasBody) > .center.🁣 > :not(.body):not(.bounds) {
  height: var(--center-height);
}

.Accordion:not(.focusHasBody) > .center.🀱 {
  width: var(--center-width);
}

.Accordion:not(.focusHasBody) > .center.🀱 > .segmentLabel {
  writing-mode: vertical-rl;
  padding: 1.125rem 1rem;
}

.Accordion > .north {
  left: var(--x);
  top: calc(var(--y)  - var(--nearNorth-height)  - var(--north-height)  + var(--units) * var(--unit) );
  height: var(--unit);
  z-index: 4;
}

.Accordion > .south {
  left: var(--x);
  top: calc(var(--y)  + var(--here-height)  + var(--nearSouth-height)  + var(--units) * var(--unit) );
  width: var(--column);
  height: var(--unit);
  z-index: 4;
}

.Accordion > .west {
  left: calc(var(--x)  - var(--nearWest-width)  - var(--west-width)  + var(--units) * var(--unit) );
  top: var(--y);
  width: var(--unit);
  z-index: 4;
}

.Accordion > .west > .segmentLabel {
  writing-mode: vertical-rl;
  padding: 1.125rem 1rem;
}

.Accordion > .east {
  left: calc(var(--x)  + var(--here-width)  + var(--nearEast-width)  + var(--units) * var(--unit) );
  top: var(--y);
  width: var(--unit);
  z-index: 4;
}

.Accordion > .east > .segmentLabel {
  writing-mode: vertical-rl;
  padding: 1.125rem 1rem;
}

.Accordion > .nearWest {
  left: calc(var(--x)  - var(--nearWest-width)  + var(--headers) * var(--unit)  + var(--columns) * var(--column)  + var(--screens) * var(--screen) );
  top: var(--y);
  z-index: 3;
}

.Accordion > .nearWest:not(.hasBody) > .segmentLabel {
  writing-mode: vertical-rl;
  padding: 1.125rem 1rem;
}

.Accordion > .nearEast {
  left: calc(var(--x)  + var(--here-width)  + var(--headers) * var(--unit)  + var(--columns) * var(--column)  + var(--screens) * var(--screen) );
  top: var(--y);
  z-index: 3;
}

.Accordion > .nearEast:not(.hasBody) > .segmentLabel {
  writing-mode: vertical-rl;
  padding: 1.125rem 1rem;
}

.Accordion > .nearNorth {
  left: var(--x);
  top: calc(var(--y)  - var(--nearNorth-height)  + var(--units) * var(--unit) );
  height: var(--unit);
  z-index: 3;
}

.Accordion > .nearSouth {
  left: var(--x);
  top: calc(var(--y)  + var(--here-height)  + var(--units) * var(--unit) );
  z-index: 3;
}

:root {
  --x-offset: 0px;
  --y-offset: 0px;
}

.model:after {
  --radius: 5rem;
  left: calc(50vw - var(--radius) );
  top: calc(40vh - var(--radius) );
  border: var(--radius) solid white;
  mix-blend-mode: difference;
  content: ".";
  height: 0;
  width: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: 50%;
  transition: all .2s;
  position: fixed;
  overflow: hidden;
}

.is-scrolling .model:after {
  --radius: 3rem;
  opacity: 1;
}

.Accordion {
  --west-width: calc(var(--west-units) * var(--unit) );
  --east-width: calc(var(--east-units) * var(--unit) );
  --north-height: calc(var(--north-units) * var(--unit) );
  --south-height: calc(var(--south-units) * var(--unit) );
  --info-height: calc(var(--north-infoLines) * var(--unit) );
  --nearNorth-height: calc(var(--nearNorth-units) * var(--unit) );
  --nearSouth-height: calc(var(--nearSouth-units) * var(--unit) );
  height: var(--height);
  width: var(--width);
  contain: strict;
  --x: calc(var(--width) / 2 - 50vw + var(--x-offset) );
  --y: calc(var(--height) / 2 - 50vh + var(--y-offset) );
  --width: calc(var(--column) * 97);
  --height: 1100vh;
  position: relative;
  overflow: hidden;
}

.Accordion.focusHasBody {
  --here-width: var(--center-width);
  --here-height: var(--center-height);
  --center-width: calc(var(--center-columns) * var(--column)  + var(--center-screens) * var(--screen) );
  --center-height: var(--fit-height);
}

.Accordion:not(.focusHasBody) {
  --here-width: max(var(--peek-width), var(--center-width) );
  --here-height: max(var(--peek-height), var(--center-height) );
}

.Accordion:not(.focusHasBody).🪗🁣 {
  --center-height: calc(var(--unit) * var(--center-infoLines)  + var(--unit) );
  --center-width: var(--column);
}

.Accordion:not(.focusHasBody).🪗🀱 {
  --center-height: var(--column);
  --center-width: var(--unit);
}

.Accordion.focusIsBackground {
  --here-height: var(--column);
  --nearWest-width: calc(var(--nearWest-units) * var(--unit) );
  --nearEast-width: calc(var(--nearEast-units) * var(--unit) );
}

.Accordion:not(.focusIsBackground) {
  --nearWest-width: calc(var(--nearWest-headers) * var(--unit)  + var(--nearWest-columns) * var(--column)  + var(--nearWest-screens) * var(--screen) );
  --nearEast-width: calc(var(--nearEast-headers) * var(--unit)  + var(--nearEast-columns) * var(--column)  + var(--nearEast-screens) * var(--screen) );
}

.Accordion {
  --px: calc(50vw - var(--column) / 2 + 1.5rem);
  --py: 40vh;
}

.Accordion #virtualScreen {
  height: 100vh;
  width: 100vw;
  left: calc(var(--x)  - var(--px) );
  top: calc(var(--y)  - var(--py) );
  z-index: 30;
  outline-offset: -3px;
  pointer-events: none;
  transition: none;
  position: absolute;
}

.Accordion #virtualScreen.navigatingByScroll ~ li {
  transition: none !important;
}

.Accordion #xy {
  width: .4rem;
  height: .4rem;
  left: calc(var(--x)  - .2rem);
  top: calc(var(--y)  - .2rem);
  z-index: 40;
  background: #ad3;
  display: none;
  position: absolute;
  transform: rotate(45deg);
}

.Accordion #pivot {
  left: var(--px);
  top: var(--py);
  z-index: 40;
  mix-blend-mode: difference;
  position: fixed;
}

.Accordion #pivot:after {
  content: ".";
  height: 0;
  border: 1rem solid #0000;
  border-left-color: #fff;
  display: block;
  position: absolute;
  top: -1rem;
  left: -1rem;
  overflow: hidden;
}

.Accordion .screenBackground {
  width: 100vw;
  height: 80vh;
  outline-offset: -.5rem;
  pointer-events: none;
  left: calc(var(--x)  + var(--here-width) / 2 - 50vw);
  top: calc(var(--y)  + var(--unit) / 2 - var(--info-height) / 2 + var(--here-height) / 2 - 40vh);
  pointer-events: none;
  background: none;
  position: absolute;
}

.Accordion .aisleBackground {
  width: calc(var(--width)  - var(--west-width)  - var(--east-width) );
  height: calc(var(--height)  - var(--north-height)  - var(--south-height)  + var(--info-height) );
  box-sizing: border-box;
  pointer-events: none;
  left: var(--west-width);
  top: calc(var(--north-height)  - var(--info-height) );
  z-index: 5;
  background: none;
  border: none;
  position: absolute;
}

.Accordion {
  top: var(--padding);
  margin: auto;
  padding: 0;
  list-style: none;
  position: relative;
}

.body {
  max-height: var(--here-height);
}

.body:not(.illustrative) {
  overflow: auto;
}

.body.waiting {
  background: gray;
}

.hamburgerMenu, .hamburgerMenu:link, .hamburgerMenu:visited {
  color: #fff;
  z-index: 4;
  width: 3rem;
  height: 3rem;
  background: #00000080;
  border-radius: 100%;
  padding: .75rem;
  transition: all 1.4s;
  position: fixed;
  top: 0;
  right: 0;
}

.hamburgerMenu:hover {
  color: #000;
  background: #fff;
  border-top-right-radius: 0;
}

.hamburgerMenu.hidden {
  opacity: 0;
  pointer-events: none;
  transform: scale(5);
}

.focusIsRoot > .aisleBackground {
  opacity: 0;
}

svg {
  color: inherit;
  stroke: #00000080;
  fill: currentColor;
}

ul.overflow, ul.Accordion, ul:not(.body ul) {
  padding-inline-start: 0;
  padding-left: 0;
  list-style-type: none;
}

.Accordion.focusIsBackground.focusIsRoot > .A {
  animation: scrollIn 2s ease-out 2s;
  animation-delay: calc(5s - .2s * var(--units) );
  top: calc(var(--y)  + 70vh + var(--units) * var(--unit) );
}

@keyframes scrollIn {
  0% {
    margin-top: calc(0px + var(--units) * var(--unit)  - 100vh);
  }

  100% {
    margin-top: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=index.b36dbbe7.css.map */
