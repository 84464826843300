.Accordion {


    >.peek {
        overflow: hidden;
    }

    &.focusHasBody>.peek {
        left: calc(var(--x) + var(--column) / 2);
        top: calc(var(--y) + var(--here-height) / 2);
        width: 0px;
        height: 0px;
    }



    &.🪗🁣:not(.focusHasBody)>.peek {
        left: var(--x);
        top: var(--y);
        width: var(--here-width);
        height: var(--peek-height);
        background: #44F;
        box-sizing: border-box;
        z-index: 1;

        img {
            object-fit: cover;
            max-width: max(100vw, 100vh);
            min-width: 100%;
            height: 100%;
        }
    }

    &.🪗🀱:not(.focusHasBody)>.peek {
        left: calc(var(--x));
        top: var(--y);
        width: var(--peek-width);
        height: var(--column);
        box-sizing: border-box;

        img {
            max-width: 100vw;
            width: auto;
            height: 100%;
        }
    }

}