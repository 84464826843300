.Accordion {

    >.east {
        left: calc(var(--x) + var(--here-width) + var(--nearEast-width) + var(--units) * var(--unit));
        top: var(--y);
        width: var(--unit);
        // height: var(--here-height);
        //height: var(--column);

        >.segmentLabel {
            writing-mode: vertical-rl;
            padding: 1.125rem 1rem;
        }

        z-index: 4;
    }

}